.App {
   margin: 5vh 15% 15vh;
    padding: 0;
    height: 80vh

}

/* .container {
  height: "100vh", bgcolor: "#ff0000" 
} */

.Org-Button {
/* margin-top: 10%; */
  display: flex;
  justify-content: flex-end;
}

.Org-Button > button {
  text-transform: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-link {
    color: #61dafb;
  }
}

/* @media screen and (max-width: 1200px) {
  .App {

    margin: 0 2%;
    padding: 0;
    height: 100vh;
  }
} */